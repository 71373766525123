body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #282828;
  color:#fff
}

#root, body, html {
  margin: 0;
  padding: 0;
  height:100%
}

h2 {
  font-size: 24px;
  margin: 0;
  padding:0
}

#skills {
  width:100%
}

.container {
  text-align: center;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow:hidden
}

.header {
  background-color: #222;
  padding: 32px;
  color: #fff;
  text-shadow: 1px 1px 5px #000;
  border-bottom:1px solid #111
}

.glitch {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  border:none
}

.links {
  border-top: 1px solid #333;
  padding-top: 20px;
  margin:0
}

.links a {
  font-size: 24px;
  margin: 0 20px;
  display: inline-block;
  color: #fff;
  text-shadow:1px 1px 5px #000
}

.links a:last-child {
  margin-right: 0
}
